import * as React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Layout from "../components/layout";
import Button from "../components/Button";
import Seo from "../components/seo";

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404" />
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div>
          <title>404 Not found</title>
          <h1 className="text-center">GAME OVER</h1>
          <div className="my-5">
            <AniLink to="/">
              <Button text="Press Restart" />
            </AniLink>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
