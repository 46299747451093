import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Button = ({ text, link }) => {
  return (
    <StyledButton className="btn-hover color-2" to={link}>
      {text}
    </StyledButton>
  );
};

const StyledButton = styled(Link)`
  padding: 20px 80px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  background-size: 300% 100%;

  border-radius: 20px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;

  &:hover {
    color: white;
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  &:focus {
    outline: none;
  }

  background-image: linear-gradient(
    to right,
    #f5ce62,
    #e43603,
    #fa7199,
    #e85a19
  );
  box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
`;

export default Button;
